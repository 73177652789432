/*! purgecss start ignore */

@tailwind base;

@tailwind components;
.hamburger {
  padding: 10px 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
.hamburger:hover {
  opacity: 0.7; }
.hamburger.is-active:hover {
  opacity: 0.7; }
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #fff; }

.hamburger-box {
  width: 40px;
  height: 25px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: 2px; }
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.25s;
  transition-timing-function: ease; }
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block; }
.hamburger-inner::before {
  top: -10px; }
.hamburger-inner::after {
  bottom: -10px; }

.hamburger--slider-r .hamburger-inner {
  top: 2px; }
.hamburger--slider-r .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.25s; }
.hamburger--slider-r .hamburger-inner::after {
  top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg); }
.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0; }
.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(90deg); }

.slider-wrapper, .sub-slider-wrapper {
  position: relative;
  overflow: hidden;
  break-inside: avoid;
}

.slider-wrapper .slider, .sub-slider-wrapper .sub-slider {
  @apply bg-yellow;
  position: absolute;
  left: -200%;
  width: 100%;
  height: 3px;
  transition: 0.5s;
}

.slider-wrapper:hover .slider, .sub-slider-wrapper:hover .sub-slider {
  transition: 0.5s;
  left: 0;
}


.dropdown:hover .dropdown-menu {
  @apply block;
}

.dropdown-menu {
  @apply bg-transparent hidden;
  column-count: 1;
}

.dropdown-menu-columns {
  column-fill: auto;
  right: auto;
}

.dropdown-menu-columns-2, .dropdown-menu-columns-3, .dropdown-menu-columns-4 {
  column-count: 1;
}

@screen xl {
  .dropdown:hover .dropdown-menu {
    @apply block fixed;
  }

  .dropdown-menu {
    @apply bg-blackish bg-opacity-75 hidden;
    top: 55px;
    max-height: 180px;
  }

  .dropdown-menu-columns {
    right: 0;
  }
  .dropdown-menu-columns-2 {
    column-count: 2;
  }
  .dropdown-menu-columns-3 {
    column-count: 3;
  }
  .dropdown-menu-columns-4 {
    column-count: 4;
  }
}

.blue-spacer {
  @apply w-full items-center flex mt-12 uppercase;
  background-image: url("/build/images/blue_banner.jpg");
  background-size: cover;
  min-height: 90px;
  left: 0;
}

.sfondo-blu {
  background-image: url("/build/images/sfondo_blu.jpg");

}

.justlift {
  .blue-spacer {
    @apply w-full items-center flex mt-12 uppercase;
    background-image: url("/build/images/yellow_banner.jpg") !important;
    background-size: cover;
    min-height: 90px;
    left: 0;
  }

  .blue-spacer-text {
    @apply m-auto text-relaxedblack font-bold text-3xl text-center leading-tight;
  }

  @screen lg {
    .blue-spacer-text {
      @apply text-5xl;
    }
  }

  .sfondo-blu {
    background-image: url("/build/images/yellow_banner_complete.jpg");

  }
}

.blue-spacer-text {
  @apply m-auto text-gray-400 font-bold text-3xl text-center leading-tight;
}

@screen lg {
  .blue-spacer-text {
    @apply text-5xl;
  }
}

.yellow-button {
  @apply uppercase bg-yellow text-blackish font-bold py-2 px-10 transition-colors duration-500;
}

.yellow-button:hover {
  @apply bg-blackish text-white;
}

.yellow-button:hover img.icona-carrello {
  filter: brightness(0) invert(1)!important;
}

.grey-button {
  @apply bg-lightergray text-blackish py-2 px-10 transition-colors duration-500;
}

.grey-button:not(:disabled):hover {
  @apply bg-blackish text-white cursor-pointer;
}

.blackish-button, .blackish-gold-button {
  @apply uppercase bg-primary connect-dark:bg-surfaceA40 text-white font-bold py-2 px-10;
}

.blackish-button:hover {
  @apply bg-azzurro;
}

.blackish-gold-button:hover {
  @apply text-gold;
}

.green-button {
  @apply text-white py-2 px-10 transition-colors transition-opacity duration-500;
  background-color: #40AE49;
}

.green-button:not(:disabled):hover {
  @apply cursor-pointer;
  opacity: 0.70;
}

.green-button:disabled {
  @apply bg-lightergray;
}

.red-button {
  @apply text-white py-2 px-10 transition-colors transition-opacity duration-500;
  background-color: #D1232A;
}

.red-button:hover {
  opacity: 0.70;
}

.arrow {
  border: solid black;
  border-width: 0 6px 6px 0;
  display: inline-block;
  padding: 3px;
}

.right-arrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left-arrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.contatti-box input, .contatti-box textarea {
  @apply p-1 border-blackish border mb-3;
}

.contatti-box label {
  @apply text-sm;
}


.checkbox-label {
  display: inline-block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 20px;
  height: 20px;
  width: 20px;
  clear: both;
}

.checkbox-label input {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 50;
}

.checkbox-label .checkbox-custom {
  @apply border border-primary;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 2px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.checkbox-label input:invalid ~ .checkbox-custom {
  border: 1px solid #f56565;
}

.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #40ba59;
  border-radius: 2px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity:1;
  border: 1px solid #40ba59;
}


.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #d3d2d2;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity:1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.checkbox-label-white .checkbox-custom::after {
  border: solid white!important;
  border-width: 0 5px 5px 0!important;
}

.checkbox-label-white input:checked ~ .checkbox-custom::after {
  border: solid white!important;
  border-width: 0 5px 5px 0!important;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity:1;
  left: 5px;
  top: 1px;
  width: 8px;
  height: 14px;
  border: solid #d3d2d2;
  border-width: 0 5px 5px 0;
  background-color: transparent;
  border-radius: 2px;
}

.flex-row-grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.flex-row-grid::after {
  content: "";
  min-width: 23rem;
}

.close-button, .close-button-white {
  background-repeat: no-repeat;
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  display: block;
  background-position: 0 0;
  border: none;
}

.close-button, .close-button-white {
  height: 35px;
  width: 35px;
  position: relative;
  box-sizing: border-box;
  line-height: 16px;
  display: inline-block;
}

.close-button::after, .close-button::before, .close-button-white::after, .close-button-white::before {
  transform: rotate(-45deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2.5px;
  margin-left: -17.5px;
  display: block;
  height: 3px;
  width: 35px;
  transition: all 0.35s ease-out;
}

.close-button::after, .close-button::before {
  @apply bg-darkgray;
}

.close-button-white::after, .close-button-white::before {
  @apply bg-white;
}

.close-button::after, .close-button-white::after {
  transform: rotate(-135deg);
}

.close-button:hover::after, .close-button-white:hover::after {
  transform: rotate(-180deg);
}

.close-button:hover::before, .close-button-white:hover::before {
  transform: rotate(0deg);
}

input:invalid, input:-moz-ui-invalid, select:invalid, textarea:invalid {
  border: 1px solid #f56565;
}

checkbox:invalid::after {
  content: '<span class="checkbox-custom border-red-500"></span>';
}

.body-blackout {
  position: fixed;
  z-index: 1010;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  display: none;
}
.body-blackout.is-blacked-out {
  display: block;
}

.column-account {
  @apply flex flex-wrap items-stretch overflow-y-auto bg-blackish bg-opacity-95 right-0 h-screen fixed px-6;
  top: 55px;
  max-width: 400px;
}

.column-account div, .column-account a {
  @apply w-full;
}
.column-account a:hover {
  @apply text-yellow;
}

.column-account a:hover img {
  filter: invert(12%) sepia(17%) saturate(2878%) hue-rotate(358deg) brightness(101%) contrast(104%);
}

.column-account hr {
  width: 100%;
  border-top: white 2px solid;
}

.column-carrello {
  @apply overflow-y-auto bg-white text-blackish right-0 h-screen w-full fixed border-blackish;
  top: 55px;
  /* border-width: 0 1px 0 1px; */
}

.column-carrello-header {
  @apply bg-yellow py-3 w-full text-blackish text-center text-2xl;
  height: 55px;
}

.column-carrello-body {
  @apply overflow-y-auto w-full block bg-darkolivegray;
  height: calc(100% - 110px);
  max-height: calc(100% - 110px);
}

.column-carrello-footer {
  @apply w-full text-center flex items-center bg-darkolivegray;
  height: 55px;
}

.column-carrello-item {
  @apply text-center m-auto my-2 bg-white transition-all duration-700;
  width: 98%;
}

.column-carrello-item-data {
  @apply table-row flex items-center py-2;
}

.carrello-item-data {
  @apply flex items-center py-0;
}

.carrello-item-data-container {
  @apply h-full w-full;
}

.carrello-item-data .carrello-item-cell  {
  @apply border-white border-l-2 flex items-center text-center;
}


.carrello-item-data .carrello-item-cell-mobile  {
  @apply border-white border-l-2 flex items-center text-center flex-wrap py-1 px-1;
  border-top: 1px solid lightgray;
}

.carrello-item-data .carrello-item-cell p, .products-item-data .products-item-cell p {
  @apply w-full m-auto;
}

.column-carrello-item-data div {
  @apply inline-block;
}

.column-carrello-item-collegati, .column-carrello-offerte-item-collegati {
  @apply flex flex-wrap justify-around;
}

.mostra_accessori_carrello {
  @apply font-bold text-sm m-auto w-full bg-black cursor-pointer text-white p-1 mt-1;
}

.mostra_accessori_carrello:hover {
  @apply text-gold;
}

.quantita-carrello-prodotto { background-color: white; border: 1px solid lightgray; font-weight: 700!important; font-size: large; width: 24px; height: 24px; display: inline-block; text-align: center; line-height: 21px; cursor: pointer; }
.quantita-carrello-prodotto:hover { border: 1px solid grey; }
.quantita-carrello-prodotto-input { background-color: transparent; border: none; display: inline-block; width: 3ch; text-align: center }
.quantita-carrello-prodotto:focus { border: none; box-shadow: none; }

.carrello-box-indirizzo {
  @apply border-primary border-2 p-2 text-xs;
}


.carrello-title {
  @apply text-2xl font-light;
}

.numberCircle {
  @apply text-center text-white text-lg bg-olivegray;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.rimuovi-articolo {
  @apply w-full text-red-700 cursor-pointer underline font-semibold;
}

.rimuovi-articolo-incorso::after {
  content: "";
  background-image: url('/build/images/loader.svg');
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 4px;
}

.total-carrello {
  @apply absolute mb-5 text-center font-bold text-yellow text-lg;
}

.total-carrello-offerte {
  @apply text-center font-bold text-yellow text-lg;
}

.carrello-indirizzi {
  @apply w-full p-2;
}

.menu-carrello {
  @apply flex flex-grow bg-blackish bg-opacity-75 text-sm transition duration-700 ease-in h-full whitespace-nowrap px-3 items-center capitalize cursor-pointer;
}

.menu-carrello-pieno {
  @apply bg-yellow bg-opacity-100;
}

.menu-carrello-pieno img {
  filter: opacity(100%) brightness(0%);
}

.menu-carrello-pieno .total-carrello, .menu-carrello-pieno .total-carrello-offerte {
  @apply text-blackish;
}

@screen lg {
  .menu-carrello {
    @apply px-2;
  }

  .column-carrello {
    @apply w-1/2;
    max-width: 670px;
  }

  .carrello-box-indirizzo {
    @apply p-3;
  }

  .carrello-item-data {
    @apply items-stretch;
  }

  .carrello-item-data .carrello-item-cell-mobile, .carrello-item-data .carrello-item-cell {
    @apply flex items-center bg-verylightgray flex-wrap px-0;
  }

  .carrello-item-data-synced .carrello-item-cell-mobile, .carrello-item-data-synced .carrello-item-cell {
    background-color: #A3CF9A!important;
  }

  .carrello-item-data-container {
    width: calc(100% - 40px);
  }

}

@screen rxl {
  .carrello-indirizzi {
    width: calc(25% - 40px);
  }

  .column-carrello-item-collegati {
    max-width: calc(100vw - 500px);
  }
}

.carrello-header-cell {
  @apply border-l-2 border-blackish text-sm  text-center;
}

.carrello-header-cell-mobile {
  @apply text-sm  text-left;
  width: 100% !important;
}

.container-carrello .col1 {
  width: 30%;
}

.container-carrello .col2 {
  width: 30%;
}

.container-carrello .col3 {
  width: 30%;
}

.container-carrello .col3-mobile {
  width: 100%;
}

.carrello-totale {
  @apply font-bold text-sm px-2 py-2 w-1/2 text-center border-white border-l-2 flex-grow;
}

.carrello-totale .somma {
  @apply font-semibold block text-base;
}

.carrello-totale .somma::before {
  content: '\A';
}

@screen lg {

  .carrello-totale {
    @apply border-l-2 text-lg w-auto;
  }

  .carrello-totale .somma {
    @apply text-2xl;
  }

  .container-carrello .col1 {
    width: 16%;
  }

  .container-carrello .col2 {
    width: 20%;
  }

  .container-carrello .col3, .container-carrello .col3-mobile {
    width: 10.666%;
  }

  .carrello-header-cell-mobile {
    @apply hidden;
  }
}

.rectangle-speech-border {
  position:relative;
  padding:15px 15px;
  margin:1em 0 3em;
  border:10px solid #939598;
  text-align:center;
  color:#333;
  background:#fff;
  /* css3 */
  -webkit-border-radius:20px;
  -moz-border-radius:20px;
  border-radius:20px;
}

.modal-form {
  @apply w-full bg-white py-6 px-4;
}

.modal-form input, .modal-form select {
  @apply w-full p-1 border border-darkolivegray mb-4;
  border-radius: 1px;
}

.modal-form label {
  @apply w-full text-base;
}

.box-offerta .title {
  @apply text-base pr-4 mt-2;
}

.box-offerta input, .box-offerta textarea, .box-offerta select {
  @apply w-full p-1 border-blackish border text-base;
}

.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  max-height: 400px;
  overflow-y: scroll;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}
.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}
.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}


.column-products-item {
  @apply text-center m-auto my-2 bg-white transition-all duration-700;
  width: 98%;
}

.column-products-item-data {
  @apply table-row flex items-center py-2;
}

.products-item-data {
  @apply flex py-0 bg-white;
}

.products-item-data-container {
  @apply h-full w-full;
}

.products-item-data .products-item-cell  {
  @apply border-darkolivegray border-l-2 flex items-center text-center px-1;
}

.products-item-data .products-item-cell div {
  @apply w-full;
}

.products-item-data .products-item-cell div.quantita {
  @apply text-2xl font-semibold;
}

.products-item-data .products-item-cell div.controvalore {
  @apply font-light;
}
.products-item-data .products-item-cell {
  @apply flex items-center flex-wrap px-0 text-xl;
}

.products-header-cell {
  @apply border-l-2 border-blackish text-base text-center font-semibold;
}

table.righe-produzione, table.righe-produzione th, table.righe-produzione td {
  @apply border-blackish border p-2;
}

.rotational-toggler svg {
  --transform-rotate: 0;
  transform: rotate(0);
  transition: transform 0.5s;
}
.rotational-toggler-open svg {
  --transform-rotate: 45deg;
  transform: rotate(45deg);
}

.rotational-toggler:hover, .rotational-toggler:hover svg {
  @apply text-azzurro;
  stroke: #3bc1ef;
}

.flash-message {
  width: 100%;
  position: sticky;
  top: 55px;
  z-index: 100;
  opacity: 1;
  height: 52px;
  transition: opacity 0.5s linear, height 0.5s linear;
}

.flash-message .flash-message-container {
  @apply text-2xl shadow-md;
  padding: 10px;
  text-align: center;
  width: 100%;
  position: relative;
  margin-top: 3px;
}

.flash-success {
  @apply bg-verdeselezionato;
}

table.table-zallys  {
  @apply border border-olivegray border-collapse w-full text-base;
}

table.table-zallys thead th {
  @apply p-2 bg-tableheader border-l border-r border-olivegray;
}

table.table-zallys tbody td {
  @apply p-2 border-l border-r border-olivegray;
}

table.table-zallys tfoot td {
  @apply p-2 border-l border-r border-olivegray;
}

table.table-zallys tbody tr:nth-child(even) {
  @apply bg-tablerowlight;
}

table.table-zallys tbody tr:nth-child(even):hover {
  @apply bg-darkolivegray text-white;
}

table.table-zallys tbody tr:nth-child(odd) {
  @apply bg-tablerowdark;
}

table.table-zallys tbody tr:nth-child(odd):hover {
  @apply bg-darkolivegray;
}

.matricola-container {
  margin-bottom: 2px;
  padding: 2px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked + .slider {
  background-color: #2196F3;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.footer-connect-tab {
  @apply text-textPrimary flex flex-wrap justify-center text-xs font-bold transition duration-700 ease-in h-full whitespace-nowrap px-3 items-center capitalize cursor-pointer;
}

.footer-connect-tab div.icon {
  @apply bg-white rounded-md p-1 text-black;
  width: 40px;
}

.footer-connect-tab div.icon img {
  filter: brightness(0);
  height: 32px;
}

.footer-connect-tab-selected div.icon {
  @apply bg-buttonAlternativeAccent text-white;
}

.footer-connect-tab-selected div.icon img {
  filter: brightness(0) invert(1);
}

.tab-content {
  animation: fadeEffect 1s; /* Fading effect takes 1 second */
  padding-bottom: 75px;
  min-height: calc(100vh - 96px - 76px);
}

/* Go from zero to full opacity */
@keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}

.fab-wrapper {
  position: fixed;
  bottom: 7rem;
  right: 2rem;
}
.fab-checkbox {
  display: none;
}
.fab {
  position: absolute;
  bottom: -1rem;
  right: -1rem;
  width: 4rem;
  height: 4rem;
  background: blue;
  border-radius: 50%;
  background: #33cc33;
  box-shadow: 0px 5px 20px rgba(27, 204, 0, 0.66);
  transition: all 0.3s ease;
  z-index: 1;
  border-bottom-right-radius: 6px;
  border: 1px solid #33cc33;
}

.fab:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}
.fab-checkbox:checked ~ .fab:before {
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  background-color: rgba(255, 255, 255, 0.2);
}
.fab:hover {
  background: #78ee78;
  box-shadow: 0px 5px 20px 5px #aef181;
}

.fab-dots {
  position: absolute;
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  transform: translateX(0%) translateY(-50%) rotate(0deg);
  opacity: 1;
  animation: blink 3s ease infinite;
  transition: all 0.3s ease;
}

.fab-dots-1 {
  left: 15px;
  animation-delay: 0s;
}
.fab-dots-2 {
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation-delay: 0.4s;
}
.fab-dots-3 {
  right: 15px;
  animation-delay: 0.8s;
}

.fab-checkbox:checked ~ .fab .fab-dots {
  height: 6px;
}

.fab .fab-dots-2 {
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
}

.fab-checkbox:checked ~ .fab .fab-dots-1 {
  width: 32px;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.fab-checkbox:checked ~ .fab .fab-dots-3 {
  width: 32px;
  border-radius: 10px;
  right: 50%;
  transform: translateX(50%) translateY(-50%) rotate(-45deg);
}

@keyframes blink {
  50% {
    opacity: 0.25;
  }
}

.fab-checkbox:checked ~ .fab .fab-dots {
  animation: none;
}

.fab-wheel {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid #33cc33;
  width: 10rem;
  height: 10rem;
  transition: all 0.3s ease;
  transform-origin: bottom right;
  transform: scale(0);
}

.fab-checkbox:checked ~ .fab-wheel {
  transform: scale(1);
}
.fab-action {
  position: absolute;
  background: #0f1941;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: White;
  box-shadow: 0 0.1rem 1rem #33cc33;
  transition: all 1s ease;

  opacity: 0;
}

.fab-checkbox:checked ~ .fab-wheel .fab-action {
  opacity: 1;
}

.fab-action:hover {
  background-color: #f16100;
}

.fab-wheel .fab-action-1 {
  right: -1rem;
  top: 0;
}

.fab-wheel .fab-action-2 {
  right: 3.4rem;
  top: 0.5rem;
}
.fab-wheel .fab-action-3 {
  left: 0.5rem;
  bottom: 3.4rem;
}
.fab-wheel .fab-action-4 {
  left: 0;
  bottom: -1rem;
}

@tailwind utilities;


/*! purgecss end ignore */
